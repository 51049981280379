import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Check from './fields/check';
import Text from './fields/text';
import TextNumber from './fields/textNumber';

import Select from './fields/select';
import Textarea from './fields/textarea';
import Isvg from 'react-inlinesvg';
import { Container, Row, Col } from 'reactstrap';

const required = (value) => (value ? undefined : 'Required');

const renderSelectField = ({
  input,
  placeholder,
  label,
  meta: { touched, error },
  children,
  additionalAction,
  scope,
  disabled,
}) => (
  <Select
    placeholder={placeholder}
    label={label}
    errorText={touched && error}
    error={touched && error}
    additionalAction={additionalAction}
    scope={scope}
    {...input}
    children={children}
    disabled={disabled}
  />
);

const renderCheckField = ({
  input,
  placeholder,
  label,
  meta: { touched, error },
}) => (
  <Check
    placeholder={placeholder}
    label={label}
    errorText={touched && error}
    error={touched && error}
    // multilang={multilang}
    // lang={lang}

    {...input}
  />
);

const renderTextField = ({
  input,
  label,
  placeholder,
  meta: { touched, error },
  type,
}) => (
  <Text
    placeholder={placeholder}
    errorText={touched && error}
    label={label}
    error={touched && error}
    type={type}
    {...input}
  />
);

const renderTextNumberField = ({
  input,
  label,
  placeholder,
  disabled,
  meta: { touched, error },
  type,
}) => (
  <TextNumber
    placeholder={placeholder}
    errorText={touched && error}
    label={label}
    disabled={disabled}
    error={touched && error}
    type={type}
    {...input}
  />
);

const renderTextareaField = ({
  input,
  placeholder,
  label,
  meta: { touched, error },
}) => (
  <Textarea
    placeholder={placeholder}
    label={label}
    errorText={touched && error}
    error={touched && error}
    {...input}
  />
);

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seePassword: false,
    };
  }

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit} className="login-form register-form">
        <Row>
          <Col lg="12">
            <div className="field-wrap">
              <Field
                name="firstName"
                component={renderTextField}
                label={'Förnamn *'.translate(this.props.lang)}
                validate={[required]}
              ></Field>
            </div>
          </Col>
          <Col lg="12">
            <div className="field-wrap">
              <Field
                name="lastName"
                component={renderTextField}
                label={'Efternamn *'.translate(this.props.lang)}
                validate={[required]}
              ></Field>
            </div>
          </Col>
          <Col lg="12">
            <div className="field-wrap">
              <Field
                name="socialSecurityNumber"
                component={renderTextField}
                label={'Personnummer *'.translate(this.props.lang)}
                validate={[required]}
              ></Field>
            </div>
          </Col>
          <Col lg="12">
            <div className="field-wrap">
              <Field
                name="deliveryAddress"
                component={renderTextField}
                label={'Gatuadress *'.translate(this.props.lang)}
                validate={[required]}
              ></Field>
            </div>
          </Col>

          <Col lg="12">
            <div className="field-wrap">
              <Field
                name="zipCode"
                component={renderTextNumberField}
                label={'Postnummer *'.translate(this.props.lang)}
                validate={[required]}
              ></Field>
            </div>
          </Col>
          <Col lg="12">
            <div className="field-wrap">
              <Field
                name="place"
                component={renderTextField}
                label={'Ort *'.translate(this.props.lang)}
                validate={[required]}
              ></Field>
            </div>
          </Col>
          <Col lg="12">
            <div className="field-wrap">
              <Field
                  name="country"
                  disabled
                  component={renderSelectField}
                  label={'Land *'}
                  validate={[required]}
              >
                <option value="">{'Välj land / region...'.translate(this.props.lang)}</option>
                <option value="AT">{'Österrike'.translate(this.props.lang)}</option>
                <option value="BE">{'Belgien'.translate(this.props.lang)}</option>
                <option value="BG">{'Bulgarien'.translate(this.props.lang)}</option>
                <option value="CY">{'Cypern'.translate(this.props.lang)}</option>
                <option value="CZ">{'Tjeckien'.translate(this.props.lang)}</option>
                <option value="DE">{'Tyskland'.translate(this.props.lang)}</option>
                <option value="DK">{'Danmark'.translate(this.props.lang)}</option>
                <option value="EE">{'Estland'.translate(this.props.lang)}</option>
                <option value="ES">{'Spanien'.translate(this.props.lang)}</option>
                <option value="FI">{'Finland'.translate(this.props.lang)}</option>
                <option value="FR">{'Frankrike'.translate(this.props.lang)}</option>
                <option value="GR">{'Grekland'.translate(this.props.lang)}</option>
                <option value="HR">{'Kroatien'.translate(this.props.lang)}</option>
                <option value="HU">{'Ungern'.translate(this.props.lang)}</option>
                <option value="IE">{'Irland'.translate(this.props.lang)}</option>
                <option value="IT">{'Italien'.translate(this.props.lang)}</option>
                <option value="LT">{'Litauen'.translate(this.props.lang)}</option>
                <option value="LU">{'Luxemburg'.translate(this.props.lang)}</option>
                <option value="LV">{'Lettland'.translate(this.props.lang)}</option>
                <option value="MT">{'Malta'.translate(this.props.lang)}</option>
                <option value="NL">{'Nederländerna'.translate(this.props.lang)}</option>
                <option value="PL">{'Polen'.translate(this.props.lang)}</option>
                <option value="PT">{'Portugal'.translate(this.props.lang)}</option>
                <option value="RO">{'Rumänien'.translate(this.props.lang)}</option>
                <option value="SE" selected="selected">{'Sverige'.translate(this.props.lang)}</option>
                <option value="SI">{'Slovenien'.translate(this.props.lang)}</option>
                <option value="SK">{'Slovakien'.translate(this.props.lang)}</option>
                <option value="GB">{'Storbritannien'.translate(this.props.lang)}</option>
              </Field>
            </div>
          </Col>

          {/* <Col lg="12">
                        <div className="field-wrap shipping-address">
                            <div className="check-wrap">
                                <Field
                                    name="sameAsDeliveryAddress"
                                    component={renderCheckField}
                                    label={"Samma som leveransadress".translate(this.props.lang)}
                                    placeholder=""
                                ></Field>

                            </div>

                            <Field
                                name="invoiceAddress"
                                component={renderTextField}
                                label={"Fakturaadress *".translate(this.props.lang)}
                                validate={!this.props.sameAsDeliveryAddress ? [required] : []}
                            ></Field>
                        </div>
                    </Col> */}

          <Col lg="12">
            <div className="field-wrap">
              <Field
                name="phoneNotification"
                component={renderTextField}
                label={'Telefon *'.translate(this.props.lang)}
                validate={[required]}
              ></Field>
            </div>
          </Col>

          <Col lg="12">
            <div className="field-wrap">
              <Field
                name="invoiceEmail"
                component={renderTextField}
                label={'E-postadress *'.translate(this.props.lang)}
                validate={[required]}
              ></Field>
            </div>
          </Col>
          {/* <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="description"
                                component={renderTextField}
                                label={"Viket företag jobbar du på? *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col> */}

          <Col lg="12">
            <p className="register-text">
              {'Din personliga data kommer endast att användas för att hantera beställningar, kundservice, för att förbättra din upplevelse av webbplatsen och eventuellt annat som står i vår integritetspolicy.'.translate(
                this.props.lang,
              )}
            </p>
          </Col>
          <Col lg="12" style={{ marginBottom: 15 }}>
            <div className="check-wrap">
              <Field
                name="privacyPolicy"
                component={renderCheckField}
                label={
                  <>
                    {'Jag har läst och accepterar '.translate(this.props.lang)}
                    <Link to="/personuppgiftspolicy">
                      {'integritetspolicyn'.translate(this.props.lang)}
                    </Link>
                  </>
                }
                placeholder=""
                validate={[required]}
              ></Field>
            </div>
          </Col>
          <Col lg="12" style={{ marginBottom: 15 }}>
            <div className="check-wrap">
              <Field
                name="newsletter"
                component={renderCheckField}
                label={'Registrera mig för nyhetsbrevet! *'.translate(
                  this.props.lang,
                )}
                placeholder=""
              ></Field>
            </div>
          </Col>
          <Col lg="12" className="btn-wrap">
            {this.props.loading ? null : (
              <button className="button">
                {'SKICKA'.translate(this.props.lang)}
              </button>
            )}
          </Col>
        </Row>
      </form>
    );
  }
}

RegisterForm = reduxForm({
  form: 'registerForm', // a unique identifier for this form
})(RegisterForm);

// Decorate with connect to read form values
const selector = formValueSelector('registerForm'); // <-- same as form name
RegisterForm = connect((state) => {
  const sameAsDeliveryAddress = selector(state, 'sameAsDeliveryAddress');
  return {
    sameAsDeliveryAddress: sameAsDeliveryAddress,
  };
})(RegisterForm);

export default RegisterForm;
