
 export const careAdviceLanguage  = [
     {name: 'SE', code: 'sv'},
     {name: 'DK', code: 'dk'},
     {name: 'NO', code: 'no'},
     {name: 'FI', code: 'fi'},
     {name: 'IE', code: 'ie'},
     {name: 'GB', code: 'en'},
     {name: 'NL', code: 'nl'},
     {name: 'BE', code: 'be'},
     {name: 'DE', code: 'de'},
     {name: 'PL', code: 'pl'},
     {name: 'FR', code: 'fr'},
     {name: 'ES', code: 'es'},
     {name: 'SK', code: 'sk'},
     {name: 'HU', code: 'hu'},
     {name: 'HR', code: 'hr'},
     {name: 'IT', code: 'it'},
     {name: 'EE', code: 'ee'},
     {name: 'LV', code: 'lv'},
     {name: 'LT', code: 'lt'},
     {name: 'RO', code: 'ro'},
     {name: 'BG', code: 'bg'},
     {name: 'GR', code: 'gr'},
     {name: 'TR', code: 'tr'},
     {name: 'SI', code: 'si'},
     {name: 'CZ', code: 'cz'},
     {name: 'PT', code: 'pt'},
]