import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
// import { Link, Redirect } from 'react-router-dom';

import Check from './fields/check';
import Text from './fields/text';
import Textarea from './fields/textarea';
import Isvg from 'react-inlinesvg';
import DatePicker from './fields/date';

import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

const required = (value) => (value ? undefined : 'Required');

const renderCheckField = ({
  input,
  placeholder,
  label,
  meta: { touched, error },
}) => (
  <Check
    placeholder={placeholder}
    label={label}
    errorText={touched && error}
    error={touched && error}
    // multilang={multilang}
    // lang={lang}

    {...input}
  />
);

const renderTextField = ({
  input,
  label,
  placeholder,
  meta: { touched, error },
  type,
}) => (
  <Text
    placeholder={placeholder}
    errorText={touched && error}
    label={label}
    error={touched && error}
    type={type}
    {...input}
  />
);

const renderTextareaField = ({
  input,
  placeholder,
  label,
  meta: { touched, error },
}) => (
  <Textarea
    placeholder={placeholder}
    label={label}
    errorText={touched && error}
    error={touched && error}
    {...input}
  />
);
const renderDatePickerField = ({
  input,
  placeholder,
  label,
  meta: { touched, error },
}) => (
  <DatePicker
    placeholder={placeholder}
    label={label}
    errorText={touched && error}
    error={touched && error}
    {...input}
  />
);

class form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seePassword: false,
    };
  }

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit} className="login-form facture-form">
        <Row>
          <Col lg="12" style={{ marginTop: 5, marginBottom: 8 }}>
            <div className="check-wrap">
              <Field
                name="termsAndConditions"
                component={renderCheckField}
                label={
                  <>
                    {'Jag har läst och godkänner '.translate(this.props.lang)}
                    <Link to="/page/kopvillkor">
                      {'köpvillkor'.translate(this.props.lang)}
                    </Link>
                  </>
                }
                placeholder=""
                validate={[required]}
              ></Field>
            </div>
          </Col>
          <Col lg="12" style={{ marginBottom: 35 }}>
            <div className="check-wrap">
              <Field
                name="partialDelivery"
                component={renderCheckField}
                label={'Jag önskar delleverans om en eller flera produkter är restnoterade (valfritt)'.translate(
                  this.props.lang,
                )}
                placeholder=""
              ></Field>
            </div>
          </Col>
          <h6 className="facture-form-text">
            {'Frakt'.translate(this.props.lang)}
          </h6>
          <p className="facture-form-text">
            {'Om ingen annan information ges gällande fraktbokning för er order, som t.ex bokning av bakgavellyft och avvikande öppettider, så kommer er beställning bokas enligt de överenskommelser vi har sedan tidigare. Önskar ni veta ca fraktpris på er order så ber vi er vänligen skicka förfrågan om detta till info@venturedesign.se'.translate(
              this.props.lang,
            )}{' '}
            <strong>{'INNAN'.translate(this.props.lang)}</strong>{' '}
            {'ni placera er order. '.translate(this.props.lang)}
          </p>

          <Col lg="12">
            <div className="field-wrap">
              <Field
                name="freightMarks"
                component={renderTextField}
                label={'Övrig information till lagret:'.translate(
                  this.props.lang,
                )}
              ></Field>
            </div>
          </Col>

          <Col lg="12">
            <div className="field-wrap">
              <Field
                name="deliveryDate"
                component={renderDatePickerField}
                label={'Önskat skeppningsdatum:'.translate(this.props.lang)}
                placeholder={'Lämna tomt för snabbast möjliga leverans'.translate(
                  this.props.lang,
                )}
              ></Field>
            </div>
          </Col>
          <Col lg="12">
            <div className="field-wrap">
              <Field
                name="orderReference"
                component={renderTextField}
                label={'Ange orderreferens:'.translate(this.props.lang)}
                placeholder={'Valfritt, ange önskad orderreferens'.translate(
                  this.props.lang,
                )}
              ></Field>
            </div>
          </Col>

          <Col lg="12" className="btn-wrap">
            {this.props.loading ? null : (
              <button className="button">
                {'SLUTFÖR KÖP'.translate(this.props.lang)}
              </button>
            )}
          </Col>
        </Row>
      </form>
    );
  }
}

export default reduxForm({
  form: 'form', // a unique identifier for this form
})(form);
