import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';

import { Container, Row, Col } from 'reactstrap';

import ContactForm from '../components/forms/contactForm';
import t1 from '../assets/images/t1.jpg';

var striptags = require('striptags');
import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from '../helpers/linkHelper';
import { API_ENDPOINT } from '../constants';

class ContactPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
      items: [],
    };
  }

  get = () => {
    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
      ).then((data) => {
        this.setState({
          ...data,
        });
      });
    }

    let params = getSearchParams(this.props[0].location.search, {});

    if (this.props.loadDataWithQuery) {
      for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
        this.props.loadDataWithQuery[i](
          window.fetch,
          this.props.lang,
          this.props[0].match,
          params,
          this.props[0].location.pathname,
        ).then((data) => {
          this.setState(
            {
              ...data,
              loading: null,
            },
            () => {
              this.props.updateMeta(
                this.props.generateSeoTags(this.state, this.props.lang),
              );
            },
          );
        });
      }
    }

    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang),
    );
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }

    this.get();
  }

  contact = (data) => {
    this.setState({
      loading: true,
    });
    console.log(data);

    fetch(API_ENDPOINT + '/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          msg: 'You have successfully contacted Venture design, expect a response soon',
        });
      });
  };

  render() {
    return (
      <div className="contact-page">
        <h1>{'Kontakt'.translate(this.props.lang)}</h1>

        <div className="main-contact">
          <Container>
            <Row>
              <Col lg="6">
                <table class="contact-table">
                  <tbody>
                    <tr>
                      <td className="uppercase contact-title">
                        {'Besöksadress:'.translate(this.props.lang)}
                      </td>
                      <td>Deltavägen 12, 35245 Växjö, Sweden</td>
                    </tr>
                    <tr>
                      <td className="uppercase contact-title">
                        {'Växel:'.translate(this.props.lang)}
                      </td>
                      <td>+46 470 55 40 00</td>
                      <td className="uppercase contact-title">&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td className="uppercase contact-title">
                        {'Telefontider:'.translate(this.props.lang)}
                      </td>
                      <td>
                        {'Mån-tor'.translate(this.props.lang)} 09:30-15:00
                        <br />
                        {'Fredagar'.translate(this.props.lang)} 10:00-14:00
                        <br />
                        {'Lunchstängt'.translate(this.props.lang)} 12:00-13:00
                      </td>
                    </tr>
                    <tr>
                      <td className="uppercase contact-title">
                        {'Fakturafrågor:'.translate(this.props.lang)}
                      </td>
                      <td>
                        <a href="mailto:ekonomi@venturedesign.se">
                          ekonomi@venturedesign.se
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="uppercase contact-title">
                        {'Reklamation:'.translate(this.props.lang)}
                      </td>
                      <td>
                        <a href="mailto:claim@venturedesign.se">
                          claim@venturedesign.se
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="uppercase contact-title">
                        {'Order:'.translate(this.props.lang)}
                      </td>
                      <td>
                        <a href="mailto:order@venturedesign.se">
                          order@venturedesign.se
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="uppercase contact-title">
                        {'ÖVRIG INFO:'.translate(this.props.lang)}
                      </td>
                      <td>
                        <a href="mailto:info@venturedesign.se">
                          info@venturedesign.se
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
              <Col lg="6">
                <div className="contact-form-container">
                  <ContactForm
                    lang={this.props.lang}
                    loading={this.state.loading}
                    onSubmit={this.contact}
                  />
                  {this.state.msg ? (
                    <p style={{ marginTop: 10 }}>{this.state.msg}</p>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {this.state.items.map((item, idx) => {
          return (
            <div className="team">
              <Container fluid={true}>
                <Row>
                  <Col lg="2">
                    <div className="team-title">
                      <h2>{Object.translate(item, 'name', this.props.lang)}</h2>
                    </div>
                  </Col>
                </Row>
                <Row>
                  {item.team.map((employee, eidx) => {
                    return (
                      <Col lg="3">
                        <div className="box has-hover team-box  has-hover box-text-bottom">
                          <div className="box-image">
                            <div className="image-grayscale">
                              <img
                                src={
                                  API_ENDPOINT.replace('testapi', 'api') +
                                  employee.image
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="box-text text-center" /*style="background-color:rgb(237, 234, 230);"*/
                          >
                            <div className="box-text-inner">
                              <div className="team-wrapper">
                                <div className="team-name">
                                  <h4>{employee.name}</h4>
                                </div>
                                <div className="team-border">&nbsp;</div>
                                <div className="team-titles">
                                  {Object.translate(
                                    employee,
                                    'description',
                                    this.props.lang,
                                  )}
                                </div>
                                <div className="team-phone">
                                  <a href={'tel:' + employee.phone}>
                                    {employee.phone}
                                  </a>
                                </div>
                                <div className="team-email">
                                  <a href={'mailto:' + employee.email}>
                                    {employee.email}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Container>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Page(ContactPage);
