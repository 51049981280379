import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
// import { Link, Redirect } from 'react-router-dom';

import Check from './fields/check';
import Text from './fields/text';
import TextNumber from './fields/textNumber';
import Select from './fields/select';
import Textarea from './fields/textarea';
import Isvg from 'react-inlinesvg';
import { connect } from 'react-redux';

import { Container, Row, Col } from 'reactstrap';

const required = (value) => (value ? undefined : 'Required');
const renderTextNumberField = ({
  input,
  label,
  placeholder,
  disabled,
  meta: { touched, error },
  type,
}) => (
  <TextNumber
    placeholder={placeholder}
    errorText={touched && error}
    label={label}
    disabled={disabled}
    error={touched && error}
    type={type}
    {...input}
  />
);

const renderCheckField = ({
  input,
  placeholder,
  label,
  meta: { touched, error },
}) => (
  <Check
    placeholder={placeholder}
    label={label}
    errorText={touched && error}
    error={touched && error}
    // multilang={multilang}
    // lang={lang}

    {...input}
  />
);

const renderSelectField = ({
  input,
  placeholder,
  label,
  meta: { touched, error },
  children,
  additionalAction,
  scope,
}) => (
  <Select
    placeholder={placeholder}
    label={label}
    errorText={touched && error}
    error={touched && error}
    additionalAction={additionalAction}
    scope={scope}
    {...input}
    children={children}
  />
);

const renderTextField = ({
  input,
  label,
  placeholder,
  meta: { touched, error },
  type,
}) => (
  <Text
    placeholder={placeholder}
    errorText={touched && error}
    label={label}
    error={touched && error}
    type={type}
    {...input}
  />
);

const renderTextareaField = ({
  input,
  placeholder,
  label,
  meta: { touched, error },
}) => (
  <Textarea
    placeholder={placeholder}
    label={label}
    errorText={touched && error}
    error={touched && error}
    {...input}
  />
);

class CartAddressForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seePassword: false,
      activeTab: 'email',
      hideStandardTemplate: false
    };
  }

  componentDidUpdate(prevProps) {
    const prevEmailFormat = prevProps?.email?.format;
    const emailFormat = this.props?.email?.format;
    if (prevEmailFormat != emailFormat) {
      if(emailFormat === 'xml') {
        this.setState({
          hideStandardTemplate: true
        })
      } else {
        this.setState({
          hideStandardTemplate: false
        })
      }
      let obj = this.props.email;
      switch(emailFormat){
        case 'xlsx':
          obj.filename = obj.filename.replace(/(.csv|.xml)$/, '.xlsx');
          break;

        case 'csv':
          obj.filename = obj.filename.replace(/(.xlsx|.xml)$/, '.csv');
          break;

        case 'xml':
          obj.filename = obj.filename.replace(/(.xlsx|.csv)$/, '.xml');
          break;

        default:
          throw new Error('Invalid File Format');
      }
      this.props.changeEmail(obj);
    }


    const prevFtpFormat = prevProps?.ftp?.format;
    const ftpFormat = this.props?.ftp?.format;
    if (prevFtpFormat != ftpFormat) {
      let obj = this.props.ftp;
      obj.filename = obj.filename.replace(
        ftpFormat == 'xlsx' ? '.csv' : '.xlsx',
        ftpFormat == 'xlsx' ? '.xlsx' : '.csv',
      );
      this.props.changeFtp(obj);
    }

    const prevSftpFormat = prevProps?.sftp?.format;
    const sftpFormat = this.props?.sftp?.format;
    if (prevSftpFormat != sftpFormat) {
      let obj = this.props.sftp;
      obj.filename = obj.filename.replace(
        sftpFormat == 'xlsx' ? '.csv' : '.xlsx',
        sftpFormat == 'xlsx' ? '.xlsx' : '.csv',
      );
      this.props.changeSftp(obj);
    }
  }

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit} className="login-form accoutn-info-form">
        <Row>
          <Col lg="12">
            <div className="form-tabs">
              <button
                onClick={() => {
                  this.setState({ activeTab: 'email' });
                }}
                className={this.state.activeTab == 'email' ? 'active' : ''}
                type="button"
              >
                E-Mail
              </button>
              <button
                onClick={() => {
                  this.setState({ activeTab: 'ftp' });
                }}
                className={this.state.activeTab == 'ftp' ? 'active' : ''}
                type="button"
              >
                FTP
              </button>
              <button
                onClick={() => {
                  this.setState({ activeTab: 'sftp' });
                }}
                className={this.state.activeTab == 'sftp' ? 'active' : ''}
                type="button"
              >
                SFTP
              </button>
            </div>
          </Col>
        </Row>
        {this.state.activeTab == 'email' ? (
          <Row>
            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="email.to"
                  component={renderTextareaField}
                  label={'E-mail (Enter the address or addresses to which you would like to receive the stock list)'.translate(
                    this.props.lang,
                  )}
                  validate={[required]}
                ></Field>
              </div>
            </Col>
            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="email.skus"
                  component={renderTextareaField}
                  label={"SKU's (Leave empty if you want complete stock list)".translate(
                    this.props.lang,
                  )}
                ></Field>
              </div>
            </Col>

            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="email.format"
                  component={renderSelectField}
                  label={'Format'.translate(this.props.lang)}
                  validate={[required]}
                >
                  <option value="xlsx">XLSX</option>
                  <option value="csv">CSV</option>
                  <option value="xml">XML</option>
                </Field>
              </div>
            </Col>

            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="email.filename"
                  component={renderTextField}
                  label={'Filename (Enter the desired file name)'.translate(
                    this.props.lang,
                  )}
                  validate={[required]}
                ></Field>
              </div>
            </Col>
            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="email.template"
                  component={renderSelectField}
                  label={'Columns'.translate(this.props.lang)}
                  validate={[required]}
                >
                   <option value="standard">
                    Standard (ItemNum, Name, Qty, ETA Week, ETA Date, Available
                    On Next Receiving, Category, EAN Code)
                  </option>
                  <option value="sku-stock">Two cols (sku,stock)</option>
                </Field>
              </div>
            </Col>
            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="email.schedule"
                  component={renderSelectField}
                  label={'Schedule'.translate(this.props.lang)}
                  validate={[required]}
                >
                  <option value="0 * * * *">Every hour</option>
                  <option value="0 5 * * *">05:00</option>
                  <option value="0 20 * * *">20:00</option>
                  <option value="0 0 * * *">00:00</option>
                </Field>
              </div>
            </Col>
          </Row>
        ) : this.state.activeTab == 'ftp' ? (
          <Row>
            <Col lg="10">
              <div className="field-wrap">
                <Field
                  name="ftp.host"
                  component={renderTextField}
                  label={'Host'.translate(this.props.lang)}
                  validate={[required]}
                ></Field>
              </div>
            </Col>
            <Col lg="2">
              <div className="field-wrap">
                <Field
                  name="ftp.port"
                  component={renderTextField}
                  label={'Port'.translate(this.props.lang)}
                  validate={[required]}
                ></Field>
              </div>
            </Col>
            <Col lg="6">
              <div className="field-wrap">
                <Field
                  name="ftp.username"
                  component={renderTextField}
                  label={'Username'.translate(this.props.lang)}
                  validate={[required]}
                ></Field>
              </div>
            </Col>
            <Col lg="6">
              <div className="field-wrap">
                <Field
                  name="ftp.password"
                  component={renderTextField}
                  label={'Password'.translate(this.props.lang)}
                  validate={[required]}
                ></Field>
              </div>
            </Col>
            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="ftp.secure"
                  component={renderCheckField}
                  label={'Secure'.translate(this.props.lang)}
                ></Field>
              </div>
              <div className="field-wrap">
                <Field
                  name="ftp.secureOptions.rejectUnauthorized"
                  component={renderCheckField}
                  label={'Reject Unauthorized'.translate(this.props.lang)}
                ></Field>
              </div>
            </Col>

            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="ftp.skus"
                  component={renderTextareaField}
                  label={"SKU's (Leave empty if you want complete stock list)".translate(
                    this.props.lang,
                  )}
                ></Field>
              </div>
            </Col>

            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="ftp.format"
                  component={renderSelectField}
                  label={'Format'.translate(this.props.lang)}
                  validate={[required]}
                >
                  <option value="xlsx">XLSX</option>
                  <option value="csv">CSV</option>
                </Field>
              </div>
            </Col>

            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="ftp.filename"
                  component={renderTextField}
                  label={'Filename (Enter the desired file name)'.translate(
                    this.props.lang,
                  )}
                  validate={[required]}
                ></Field>
              </div>
            </Col>
            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="ftp.template"
                  component={renderSelectField}
                  label={'Columns'.translate(this.props.lang)}
                  validate={[required]}
                >
                  <option value="standard">
                    Standard (ItemNum, Name, Qty, ETA Week, ETA Date, Available
                    On Next Receiving, Category, EAN Code)
                  </option>
                  <option value="sku-stock">Two cols (sku,stock)</option>
                </Field>
              </div>
            </Col>
            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="ftp.schedule"
                  component={renderSelectField}
                  label={'Schedule'.translate(this.props.lang)}
                  validate={[required]}
                >
                  <option value="* * * * *">Every minute</option>
                  <option value="0 * * * *">Every hour</option>
                  <option value="0 5 * * *">05:00</option>
                  <option value="0 20 * * *">20:00</option>
                  <option value="0 0 * * *">00:00</option>
                </Field>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg="10">
              <div className="field-wrap">
                <Field
                  name="sftp.host"
                  component={renderTextField}
                  label={'Host'.translate(this.props.lang)}
                  validate={[required]}
                ></Field>
              </div>
            </Col>
            <Col lg="2">
              <div className="field-wrap">
                <Field
                  name="sftp.port"
                  component={renderTextField}
                  label={'Port'.translate(this.props.lang)}
                  validate={[required]}
                ></Field>
              </div>
            </Col>
            <Col lg="6">
              <div className="field-wrap">
                <Field
                  name="sftp.username"
                  component={renderTextField}
                  label={'Username'.translate(this.props.lang)}
                  validate={[required]}
                ></Field>
              </div>
            </Col>
            <Col lg="6">
              <div className="field-wrap">
                <Field
                  name="sftp.password"
                  component={renderTextField}
                  label={'Password'.translate(this.props.lang)}
                  validate={[required]}
                ></Field>
              </div>
            </Col>
            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="sftp.skus"
                  component={renderTextareaField}
                  label={"SKU's (Leave empty if you want complete stock list)".translate(
                    this.props.lang,
                  )}
                ></Field>
              </div>
            </Col>

            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="sftp.format"
                  component={renderSelectField}
                  label={'Format'.translate(this.props.lang)}
                  validate={[required]}
                >
                  <option value="xlsx">XLSX</option>
                  <option value="csv">CSV</option>
                </Field>
              </div>
            </Col>

            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="sftp.filename"
                  component={renderTextField}
                  label={'Filename (Enter the desired file name)'.translate(
                    this.props.lang,
                  )}
                  validate={[required]}
                ></Field>
              </div>
            </Col>
            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="sftp.template"
                  component={renderSelectField}
                  label={'Columns'.translate(this.props.lang)}
                  validate={[required]}
                >
                  <option value="standard">
                    Standard (ItemNum, Name, Qty, ETA Week, ETA Date, Available
                    On Next Receiving, Category, EAN Code)
                  </option>
                  <option value="sku-stock">Two cols (sku,stock)</option>
                </Field>
              </div>
            </Col>
            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="sftp.schedule"
                  component={renderSelectField}
                  label={'Schedule'.translate(this.props.lang)}
                  validate={[required]}
                >
                  <option value="* * * * *">Every minute</option>
                  <option value="0 * * * *">Every hour</option>
                  <option value="0 5 * * *">05:00</option>
                  <option value="0 20 * * *">20:00</option>
                  <option value="0 0 * * *">00:00</option>
                </Field>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col lg="12" className="btn-wrap" style={{ marginTop: 30 }}>
            {this.props.loading ? null : (
              <button className="button">
                {'Spara ändringen'.translate(this.props.lang)}
              </button>
            )}
          </Col>
        </Row>
      </form>
    );
  }
}

CartAddressForm = reduxForm({
  form: 'stockForm', // a unique identifier for this form
})(CartAddressForm);

// Decorate with connect to read form values
const selector = formValueSelector('stockForm'); // <-- same as form name
CartAddressForm = connect(
  (state) => {
    const email = selector(state, 'email');
    const ftp = selector(state, 'ftp');
    const sftp = selector(state, 'sftp');

    return {
      email,
      ftp,
      sftp,
    };
  },
  {
    changeEmail: (value) => change('stockForm', 'email', value),
    changeFtp: (value) => change('stockForm', 'ftp', value),
    changeSftp: (value) => change('stockForm', 'sftp', value),
  },
)(CartAddressForm);

export default CartAddressForm;
